import axios from "@/libs/axios";
import { InsuranceCompaniesTypes } from "@/types/insuranceTypes";

export  class HttpRepoInsurance {
	
	static InsuranceCompanies = {
		getData(BaseFilters) {
			return [
				{
					id: 39872,
					insuranceCompany: 'Molina Healthcare',
					shortCode: '478',
					addressOne: '417 Bicetown Road',
					addressTwo: null,
					city: 'New York',
					state: 'NY',
					country: null,
					zipcode: '10018',
					email: ['RhondaGRhodes@hotmail.com'],
					phone: [
						{
							number: '(818) 313-7673',
							type: 'work',
						}
					],
					status: 'Active',
					ipa: 'Yes',
					website: null,
				},
				{
					id: 57985,
					insuranceCompany: 'Blue Cross Blue Shield',
					shortCode: '754',
					addressOne: '4387 Farland Avenue',
					addressTwo: null,
					city: 'San Antonio',
					state: 'TX',
					country: null,
					zipcode: '78212',
					email: ['RhondaGRhodes@hotmail.com'],
					phone: [
						{
							number: '(818) 313-7673',
							type: 'work',
						}
					],
					status: 'Active',
					ipa: 'No',
					website: null,
				},
				{
					id: 66878,
					insuranceCompany: 'Health Care Service Corporation',
					shortCode: '241',
					addressOne: '2323 Dancing Dove Lane',
					addressTwo: null,
					city: 'Long Island City',
					state: 'NY',
					country: null,
					zipcode: '11101',
					email: ['RhondaGRhodes@hotmail.com'],
					phone: [
						{
							number: '(818) 313-7673',
							type: 'work',
						}
					],
					status: 'Inactive',
					ipa: 'Yes',
					website: null,
				}
			];
			// return axios.get<ListResponseModel<ProcDetailTypes>>('/Practices/GetList',{params:BaseFilters})
		},
		getOneData(BaseFilters) {
			let targetData;
			let data = this.getData(BaseFilters);
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					targetData = element;
				}
			});
			// console.log("targetData: ", targetData);
			return [targetData];
		}
	}
	static InsuranceCompaniesPlans = {
		getData(BaseFilters) {
			return [
				{
					id: 68741,
					planName: 'Rhodes Furniture',
					shortCode: '225',
					status: 'Active',
				},
				{
					id: 16574,
					planName: 'Mostow Co.',
					shortCode: '754',
					status: 'Active',
				},
				{
					id: 35738,
					planName: 'Rainbow Bay Crafts',
					shortCode: '587',
					status: 'Inactive',
				},
				{
					id: 87352,
					planName: '35738',
					shortCode: 'Electronic Geek',
					status: 'Active',
				},
			];
			// return axios.get<ListResponseModel<ProcDetailTypes>>('/Practices/GetList',{params:BaseFilters})
		}
	}
	static InsuranceCompaniesEligibilityInfo = {
		getData(BaseFilters) {
			return [
				{
					id: 39872,
					payerId: '478',
					addressOne: '417 Bicetown Road',
					addressTwo: null,
					city: 'New York',
					state: 'NY',
					country: null,
					zipcode: '10018',
					email: ['RhondaGRhodes@hotmail.com'],
					phone: [
						{
							number: '(818) 313-7673',
							type: 'work',
						}
					],
					webLinks: null
				},
				{
					id: 84233,
					payerId: '478',
					addressOne: '417 Bicetown Road',
					addressTwo: null,
					city: 'New York',
					state: 'NY',
					country: null,
					zipcode: '10018',
					email: ['RhondaGRhodes@hotmail.com'],
					phone: [
						{
							number: '(818) 313-7673',
							type: 'work',
						}
					],
					webLinks: null
				},
				{
					id: 98542,
					payerId: '478',
					addressOne: '417 Bicetown Road',
					addressTwo: null,
					city: 'New York',
					state: 'NY',
					country: null,
					zipcode: '10018',
					email: ['RhondaGRhodes@hotmail.com'],
					phone: [
						{
							number: '(818) 313-7673',
							type: 'work',
						}
					],
					webLinks: null
				}
			];
		},
		getOneData(BaseFilters) {
			let targetData;
			let data = this.getData(BaseFilters);
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					targetData = element;
				}
			});
			// console.log("targetData: ", targetData);
			return [targetData];
			// return axios.get<ListResponseModel<ProcDetailTypes>>('/Practices/GetList',{params:BaseFilters})
		}
	}
	static InsuranceCompaniesClaimsStatus = {
		getData(BaseFilters) {
			return [
				{
					id: 39872,
					claimsPayerId: null,
					statusPayerId: null,
					clearingHouse: null,
					addressOne: null,
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipcode: null,
					email: [''],
					phone: [
						{
							number: null,
							type: null,
						}
					],
					inNetwork: null,
					outNetwork: null,
					correctedClaims: null,
					acceptSecondaryClaims: null,
					acceptCorrectedClaims: null,
				}
			];
		},
		getOneData(BaseFilters) {
			let targetData;
			let data = this.getData(BaseFilters);
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					targetData = element;
				}
			});
			// console.log("targetData: ", targetData);
			return [targetData];
			// return axios.get<ListResponseModel<ProcDetailTypes>>('/Practices/GetList',{params:BaseFilters})
		}
	}
	static InsuranceCompaniesApplesInfo = {
		getData(BaseFilters) {
			return [
				{
					id: 39872,
					addressOne: null,
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipcode: null,
					email: [''],
					phone: [
						{
							number: null,
							type: null,
						}
					],
				}
			];
		},
		getOneData(BaseFilters) {
			let targetData;
			let data = this.getData(BaseFilters);
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					targetData = element;
				}
			});
			// console.log("targetData: ", targetData);
			return [targetData];
			// return axios.get<ListResponseModel<ProcDetailTypes>>('/Practices/GetList',{params:BaseFilters})
		}
	}
	static InsuranceCompaniesPaymentInfo = {
		getData(BaseFilters) {
			return [
				{
					id: 39872,
					addressOne: null,
					addressTwo: null,
					city: null,
					state: null,
					country: null,
					zipcode: null,
					email: [''],
					phone: [
						{
							number: null,
							type: null,
						}
					],
					hasElectroniFunds: null,
					setupInstructionsElectronicFunds: null,
					hasERA: null,
					setupInstructionsERA: null
				}
			];
		},
		getOneData(BaseFilters) {
			let targetData;
			let data = this.getData(BaseFilters);
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					targetData = element;
				}
			});
			// console.log("targetData: ", targetData);
			return [targetData];
			// return axios.get<ListResponseModel<ProcDetailTypes>>('/Practices/GetList',{params:BaseFilters})
		}
	}
	static InsuranceCompaniesEligibilityWeblinks = {
		getData(BaseFilters) {
			return [
				{
					id: null,
					name: 'Claims & Status Verification',
					url: 'https://webtecassian.net/jirare/E...',
					user: 'William_p',
					password: 'asd',
					comment: 'Where were you born? New York Central Hospital',
					status: 'Active',
				},
				{
					id: null,
					name: 'Status Top Securities',
					url: 'www.ponent.netlify.app/?path=/s...',
					user: 'Dana221',
					password: 'asd',
					comment: '',
					status: 'Inactive',
				},
				{
					id: null,
					name: 'SecurityLtd login',
					url: 'https://featherons.com/?query=lin...',
					user: 'WillH@SecuritiesLtd.com',
					password: 'asd',
					comment: '',
					status: 'Inactive',
				}
			];
		},
	}
	static InsurancePlans = {
		getData(BaseFilters) {
			return [
				{
					id: 84233,
					planName: 'Molina Healthcare',
					companyName: 'Molina Healthcare',
					shortCode: '754',
					planType: null,
					status: 'Active'
				},
				{
					id: 98542,
					planName: 'Blue Cross Blue Shield',
					companyName: 'Blue Cross Blue Shield',
					shortCode: '225',
					planType: null,
					status: 'Active'
				},
				{
					id: 35738,
					planName: 'Health Care Service Corporation',
					companyName: 'Health Care Service Corporation',
					shortCode: '241',
					planType: null,
					status: 'Inactive'
				},
				{
					id: 57985,
					planName: 'Health Care Service Corporation',
					companyName: 'Health Care Service Corporation',
					shortCode: '241',
					planType: null,
					status: 'Active'
				},
				{
					id: 39872,
					planName: 'Metropolitan',
					companyName: 'Metropolitan',
					shortCode: '985',
					planType: null,
					status: 'Inactive'
				}
			];
		},
		getOneData(BaseFilters) {
			let targetData;
			let data = this.getData(BaseFilters);
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					targetData = element;
				}
			});
			return [targetData];
			// return axios.get<ListResponseModel<ProcDetailTypes>>('/Practices/GetList',{params:BaseFilters})
		}
	}
	static InsurancePlansOtherInfo = {
		getData(BaseFilters) {
			return [
				{
					id: 84233,
					memberIdPrefix: '1684',
					memberIdSuffix: '699',
					addressOne: 'Abc. 1908',
					addressTwo: null,
					city: 'New York',
					state: 'New York',
					country: 'United States',
					zipcode: '11208'
				},
				{
					id: 98542,
					memberIdPrefix: '1684',
					memberIdSuffix: '699',
					addressOne: 'Abc. 1908',
					addressTwo: null,
					city: 'New York',
					state: 'New York',
					country: 'United States',
					zipcode: '11208'
				},
				{
					id: 35738,
					memberIdPrefix: '1684',
					memberIdSuffix: '699',
					addressOne: 'Abc. 1908',
					addressTwo: null,
					city: 'New York',
					state: 'New York',
					country: 'United States',
					zipcode: '11208'
				},
				{
					id: 57985,
					memberIdPrefix: '1684',
					memberIdSuffix: '699',
					addressOne: 'Abc. 1908',
					addressTwo: null,
					city: 'New York',
					state: 'New York',
					country: 'United States',
					zipcode: '11208'
				},
				{
					id: 39872,
					memberIdPrefix: '1684',
					memberIdSuffix: '699',
					addressOne: 'Abc. 1908',
					addressTwo: null,
					city: 'New York',
					state: 'New York',
					country: 'United States',
					zipcode: '11208'
				}
			];
		},
		getOneData(BaseFilters) {
			let targetData;
			let data = this.getData(BaseFilters);
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					targetData = element;
				}
			});
			return [targetData];
			// return axios.get<ListResponseModel<ProcDetailTypes>>('/Practices/GetList',{params:BaseFilters})
		}
	}
	static InsurancePlansCoverageInfoCC = {
		getData(BaseFilters) {
			return [
				{
					id: 84233,
					hasDeductible: 'Yes',
					ageRestriction: null,
					hasCoPay: 'Yes',
					hasCoInsurance: 'No',
					hasOutOfNetwork: 'No',
					isGoodPayer: 'Yes'
				},
				{
					id: 98542,
					hasDeductible: 'Yes',
					ageRestriction: null,
					hasCoPay: 'Yes',
					hasCoInsurance: 'No',
					hasOutOfNetwork: 'No',
					isGoodPayer: 'Yes'
				},
				{
					id: 35738,
					hasDeductible: 'Yes',
					ageRestriction: null,
					hasCoPay: 'Yes',
					hasCoInsurance: 'No',
					hasOutOfNetwork: 'No',
					isGoodPayer: 'Yes'
				},
				{
					id: 57985,
					hasDeductible: 'Yes',
					ageRestriction: null,
					hasCoPay: 'Yes',
					hasCoInsurance: 'No',
					hasOutOfNetwork: 'No',
					isGoodPayer: 'Yes'
				},
				{
					id: 39872,
					hasDeductible: 'Yes',
					ageRestriction: null,
					hasCoPay: 'Yes',
					hasCoInsurance: 'No',
					hasOutOfNetwork: 'No',
					isGoodPayer: 'Yes'
				}
			];
		},
		getOneData(BaseFilters) {
			let targetData;
			let data = this.getData(BaseFilters);
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					targetData = element;
				}
			});
			return [targetData];
			// return axios.get<ListResponseModel<ProcDetailTypes>>('/Practices/GetList',{params:BaseFilters})
		}
	}
	static InsurancePlansExcludedBenefits = {
		getData(BaseFilters) {
			return [
				{
					id: 84233,
					procedureType: 'Radiology Services',
    				description: 'Radiology Services'
				},
				{
					id: 98542,
					procedureType: 'Anesthesia Services',
    				description: 'Anesthesia Services'
				},
				{
					id: 57985,
					procedureType: 'Medical Services and Procedures',
    				description: 'Medical Services and Procedures'
				},
				{
					id: 39872,
					procedureType: 'Evaluation & Management Services',
    				description: 'Evaluation & Management Services'
				}
			];
		},
		getOneData(BaseFilters) {
			let targetData;
			let data = this.getData(BaseFilters);
			data.forEach((element) => {
				if(element.id == BaseFilters.SearchTerm) {
					targetData = element;
				}
			});
			return [targetData];
			// return axios.get<ListResponseModel<ProcDetailTypes>>('/Practices/GetList',{params:BaseFilters})
		}
	}
}