/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ColumnFilter,
  CopyCloneProcCodeModel,
  GeneralStatus,
  ProcedureCodesDto,
  ProcedureCodesDtoListResponseModel,
  ProcedureCodesDtoModelResponse,
  ProcedureSettingsDiagnosisDto,
  ProcedureSettingsDiagnosisDtoListResponseModel,
  ProcedureSettingsModifierDto,
  ProcedureSettingsModifierDtoListResponseModel,
  ProcedureSettingsPlaceOfServiceDto,
  ProcedureSettingsPlaceOfServiceDtoListResponseModel,
  SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ProcedureCodes<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ProcedureCodes
   * @name LoadList
   * @request GET:/ProcedureCodes/Load
   */
  loadList = (
    query?: {
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProcedureCodesDtoListResponseModel, any>({
      path: `/ProcedureCodes/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureCodes
   * @name PostProcedureCodes
   * @request POST:/ProcedureCodes/Add
   */
  postProcedureCodes = (data: ProcedureCodesDto, params: RequestParams = {}) =>
    this.request<ProcedureCodesDtoModelResponse, any>({
      path: `/ProcedureCodes/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
* No description
*
* @tags ProcedureCodes
* @name PostProcedureCodes
* @request POST:/ProcedureCodes/CloneOrCopyCode
*/
  postCloneOrCopyCode = (data: CopyCloneProcCodeModel, params: RequestParams = {}) =>
    this.request<ProcedureCodesDtoModelResponse, any>({
      path: `/ProcedureCodes/CloneOrCopyCode`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureCodes
   * @name UpdateCreate
   * @request POST:/ProcedureCodes/Update
   */
  updateCreate = (data: ProcedureCodesDto, params: RequestParams = {}) =>
    this.request<ProcedureCodesDtoModelResponse, any>({
      path: `/ProcedureCodes/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureCodes
   * @name GetByIdList
   * @request GET:/ProcedureCodes/GetById
   */
  getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<ProcedureCodesDtoModelResponse, any>({
      path: `/ProcedureCodes/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureCodes
   * @name InsertOrUpdateDiagnosisCreate
   * @request POST:/ProcedureCodes/InsertOrUpdateDiagnosis
   */
  insertOrUpdateDiagnosisCreate = (data: ProcedureSettingsDiagnosisDto[], params: RequestParams = {}) =>
    this.request<ProcedureSettingsDiagnosisDtoListResponseModel, any>({
      path: `/ProcedureCodes/InsertOrUpdateDiagnosis`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureCodes
   * @name InsertOrUpdateModifiersCreate
   * @request POST:/ProcedureCodes/InsertOrUpdateModifiers
   */
  insertOrUpdateModifiersCreate = (data: ProcedureSettingsModifierDto[], params: RequestParams = {}) =>
    this.request<ProcedureSettingsModifierDtoListResponseModel, any>({
      path: `/ProcedureCodes/InsertOrUpdateModifiers`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureCodes
   * @name InsertOrUpdatePosCreate
   * @request POST:/ProcedureCodes/InsertOrUpdatePos
   */
  insertOrUpdatePosCreate = (data: ProcedureSettingsPlaceOfServiceDto[], params: RequestParams = {}) =>
    this.request<ProcedureSettingsPlaceOfServiceDtoListResponseModel, any>({
      path: `/ProcedureCodes/InsertOrUpdatePos`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureCodes
   * @name DeleteDelete
   * @request DELETE:/ProcedureCodes/Delete
   */
  deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<ProcedureCodesDtoModelResponse, any>({
      path: `/ProcedureCodes/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
}
