/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosResponse } from "axios";
import { ClientInterface } from "./ClientInterface";
import {
  ColumnFilter,
  DtoListResponseModel,
  DtoModelResponse,
  GeneralStatus,
  ProcedureTypesDto,
  SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ProcedureTypes<SecurityDataType = unknown> extends HttpClient<SecurityDataType>  {

  /**
   * No description
   *
   * @tags ProcedureTypes
   * @name GetListList
   * @request GET:/ProcedureTypes/GetList
   */
  loadList = (
    query?: {
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<DtoListResponseModel<ProcedureTypesDto>, any>({
      path: `/ProcedureTypes/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureTypes
   * @name GetTypeList
   * @request GET:/ProcedureTypes/GetType
   */
  getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<DtoModelResponse<ProcedureTypesDto>, any>({
      path: `/ProcedureTypes/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureTypes
   * @name AddTypeCreate
   * @request POST:/ProcedureTypes/AddType
   */
  addTypeCreate = (data: ProcedureTypesDto, params: RequestParams = {}) =>
    this.request<DtoModelResponse<ProcedureTypesDto>, any>({
      path: `/ProcedureTypes/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  postPost = (data: ProcedureTypesDto, params: RequestParams = {}) =>
    this.request<DtoModelResponse<ProcedureTypesDto>, any>({
      path: `/ProcedureTypes/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureTypes
   * @name DeleteTypeDelete
   * @request DELETE:/ProcedureTypes/DeleteType
   */
  deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<DtoModelResponse<ProcedureTypesDto>, any>({
      path: `/ProcedureTypes/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProcedureTypes
   * @name UpdateTypeCreate
   * @request POST:/ProcedureTypes/UpdateType
   */
  updateCreate = (data: ProcedureTypesDto, params: RequestParams = {}) =>
    this.request<DtoModelResponse<ProcedureTypesDto>, any>({
      path: `/ProcedureTypes/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
